.headerText {
  color: white !important;
  font-weight: 500 !important;
  margin-top: 10px !important;
}

.header {
  padding-bottom: 0 !important;
  border-radius: 0 !important;
}

.accordionTitle {
  background: rgb(29, 90, 185) !important;
  color: white !important;
}

.successText {
  color: rgb(33, 186, 69);
}

.pendingText {
  color: rgb(255, 165, 0);
}

.gatePassText {
  font-size: 20px;
}

.pin {
  font-weight: bolder;
}

.label {
  margin-right: 15px !important;
}

.textAlignLeft {
  float: left;
}

.textAlignRight {
  float: right;
}

.section {
  margin-bottom: 15px;
}

.accordionHeader {
  clear: both;
}

.accordionHeader {
  height: 45px;
}

.addressDetails {
  margin-left: 0 !important;
}

.CountryDropDown {
  display: flex !important;
}

.carousel .control-prev.control-arrow,
.carousel .control-next.control-arrow {
  height: 45px;
  width: 45px;
  position: absolute;
  top: 45% !important;
  border-radius: 100%;
  color: black !important;
}

.carousel .control-prev.control-arrow {
  left: 7px !important;
}

.carousel .control-next.control-arrow {
  right: 7px !important;
}

.carousel.carousel-slider .control-arrow:hover,
.carousel.carousel-slider .control-arrow {
  background: white !important;
}

.carousel.carousel-slider .control-dots {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50px 50px;
  width: fit-content;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -40px;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #1b1c1d !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #1b1c1d !important;
}

.cityDetails {
  color: blue;
}

.facilityDetails {
  margin-top: 15px !important;
  font-weight: 100 !important;
}

.ui.dropdown > .text {
  color: white;
  padding: 5px;
  font-weight: 100;
}

.carousel.carousel-slider {
  overflow: visible !important;
}

.ui.accordion .accordion .active.content,
.ui.accordion .active.content {
  padding: 0.5em 1em 2.5em !important;
}
